import React from 'react'
// Customizable Area Start
import AppHeaderController, { configJSON } from './HeaderController.web';
import { IMenuItem } from './Types';
import './assets/Header.css';
import {
  OutlinedInput,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Popper,
  Button,
  Popover,
  Box,
  Typography,
  Menu,
  Modal,
  Grid,
  IconButton,
} from '@material-ui/core';
import {
  Search,
  Menu as MenuIcon,
  ChevronRight,
  Close,
  History,
  PersonOutlineOutlined,
  ShoppingCartOutlined,
  CheckCircleRounded
} from '@material-ui/icons';
import { withStyles, styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from "react-router-dom";
import Badge from '@material-ui/core/Badge';
import Skeleton from '@material-ui/lab/Skeleton';
import { Helmet } from "react-helmet";
import EmailAccountLoginBlock from '../../../email-account-login/src/EmailAccountLoginBlock.web';
const location = require("./assets/location.svg");
const order = require("./assets/order.svg");
const logout = require("./assets/logout.svg");
const profile = require("./assets/profile.svg");
import EmailAccountRegistration from "../../../email-account-registration/src/EmailAccountRegistration.web";
import { HeaderMenuItemsWeb } from "../../../../components/src/templates/ModernMarvel/HeaderComponents.web";
// Customizable Area End

export class AppHeader extends AppHeaderController {
  // Customizable Area Start
  render() {    
    const { classes } = this.props;
    const {
      isLoading,
      anchorEl,
      isMobile,
      isMenuOpen,
      searchValue,
      menuItems,
      selectedMenuItem,
      searchItems,
      historySearchItems,
      isSearchLoading,
      isSearchOpen,
      isSearchPage,
      gtmScript,
      isSearchEnabled
    } = this.state;

    const renderLogo = () => {
      if (isLoading) return <Skeleton variant="circle" width={50} height={50} />
      
      if (!this.state.designDetails.header.storeLogo) return (<div
        style={{
          fontFamily: `${this.state.designDetails.header.storeTextLogoDetails.logoFont}`,
          fontSize: isMobile ? 17 : 24,
          fontWeight: 500,
          letterSpacing: "-0.02em",
          color: `${this.state.designDetails.header.storeTextLogoDetails.logoColor}`,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
      }}>{this.state.customerServices.storeName}</div>)
      return <img loading="lazy" ref={this.logoImgRef} src={this.state.designDetails.header.storeLogo} alt="logo" />
    }  
    const locatioPage = window.location.pathname 
    const isShowSearchWhite = (this.state.designDetails.header.chosenHeaderTheme === "Nexus" || this.state.designDetails.header.chosenHeaderTheme === "Radiance") && locatioPage === '/' && window.scrollY < 100  
    const isShowWhiteColour = isShowSearchWhite  ? '#fff' : 'var(--design-primary-color)'
    const renderSearchItem = (item: any, index: number, isFromHistory: boolean = false) => (
      <ListItem 
        button
        key={index}
        className={isMobile ? classes.listItem : classes.searchListItem}
        onClick={() => this.handleRedirect(isFromHistory ? `/catalogue?search=${item}` : item.url)}
      >
        {isFromHistory
          ? <History className={classes.searchIcon} />
          : item.image
            ? <img src={item.image} alt={item.name} className="AppHeader__search__itemImage" />
            : <Search className={classes.searchIcon} />
        }
        <ListItemText 
          primary={<span 
            dangerouslySetInnerHTML={{ __html: (isFromHistory ? item : item.name)
              .replace(new RegExp(searchValue, 'gi'), (match: any) => `<b>${match}</b>`) }} 
          />} 
        />
      </ListItem>
    )

    const renderSearchItems = (
      <>
        {isSearchLoading
          ? <CircularProgress className={classes.searchLoader} />
          : searchItems.map((item, index) => (
            renderSearchItem(item, index)
        ))}
        {historySearchItems.length > 0 && (
          historySearchItems.filter((item: any) => item.includes(searchValue)).map((item: any, index: number) => (
            renderSearchItem(item, index, true)
          ))
        )}
      </>
    )

    const renderMobile = (
      <div className="AppHeader AppHeader--mobile" ref={this.logoContainerRef} style={window.location.pathname !== "/" ? { position: "relative", backgroundColor: "#FFF" } : { top: this.state.isHeaderVisible ? 0 : -130, backgroundColor: this.state.lastScrollY > 100 ? "#FFF" : ["Nexus", "Radiance"].includes(this.state.designDetails.header.chosenHeaderTheme) ? "transparent" : "#FFF" }}>
        <div className='AppHeader__menuMobile'>
          <MenuIcon 
            className={classes.menuIcon}
            onClick={() => this.handleMenuOpen(false)} 
            style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : (this.state.lastScrollY > 100 ? "var(--design-secondary-color)" : this.state.designDetails.header.chosenHeaderTheme === "Nexus" ? "#FFF" : "var(--design-secondary-color)"), transition: "color 0.3s" }}
          />
          <Drawer
            anchor="left"
            open={isMenuOpen}
            onClose={this.handleMenuClose}
            classes={{ paper: classes.drawerPaper }}
          >
            <div className='AppHeader__menuMobile__drawerTop'>
            <CloseIcon className={classes.closeButton} onClick={this.handleMenuClose} />
              <Close className={classes.closeButton} onClick={this.handleMenuClose} />
              <p className="AppHeader__menuMobile__title">
                {isSearchPage ? configJSON.searchPlaceholder.toUpperCase() : configJSON.menu.toUpperCase()}
              </p>
              {isSearchPage && <OutlinedInput
                onFocus={() => this.handleSearchVisibility('focus')}
                onBlur={() => this.handleSearchVisibility('blur')}
                value={searchValue}
                onChange={(e) => this.setSearchValue(e.target.value)}
                onKeyDown={(e) => this.handleSearchKeyDown(e.key)}
                placeholder={configJSON.searchPlaceholder}
                classes={{
                  root: [classes.search, classes.searchMobile].join(' '),
                  input: classes.input,
                }}
                startAdornment={<Search className={classes.searchIcon} />}
                endAdornment={<Close 
                  className={classes.closeIcon} 
                  onClick={() => this.setSearchValue('')}
                />}
              />}
            </div>
            {selectedMenuItem &&
              <span className='AppHeader__menuMobile__activeTitle'>
                {selectedMenuItem.categoryName.toUpperCase()}
              </span>
            }
            <List className={classes.list}>
              {(isSearchOpen && searchItems.length > 0) ? renderSearchItems : !isSearchPage &&
              this.getMobileMenuCatList().map((item: any, index: number) => (
               (item && <ListItem 
                  button
                  key={index}
                  className={classes.listItem}
                  onClick={(e) => {
                    if (item.itemUrl || selectedMenuItem) {
                      this.handleRedirect(item.itemUrl);
                    } else {
                      this.handleClick(e, item);
                    }
                  }}
                >
                  <ListItemText 
                    primary={item.categoryName ?? item.itemName} 
                    disableTypography 
                    className={item.categoryName ? classes.listItemTextBold : classes.listItemText}
                  />
                  {!selectedMenuItem && <ChevronRight style={{ color: 'var(--design-secondary-color)' }} />}
                </ListItem>)
              ))}
            </List>
            {!isSearchPage &&
              <Button
                classes={{ root: classes.mobileLogin }}
                onClick={this.handleMobileLogin}
              >
                {this.state.userToken ? configJSON.profileTitle : configJSON.loginSIgnUp}
              </Button>
            }
          </Drawer>
        </div>
        <div className="AppHeader__logo" onClick={() => this.handleRedirect('/')} title={this.state.customerServices.storeName}>
          {renderLogo()}
        </div>
        <div className="AppHeader__profile">
          <Search onClick={() => this.handleMenuOpen(true)} style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : (this.state.lastScrollY > 100 ? "var(--design-secondary-color)" : this.state.designDetails.header.chosenHeaderTheme === "Nexus" ? "#FFF" : "var(--design-secondary-color)"), transition: "color 0.3s" }} />
          <div className='AppHeader__shoppingCartIcon' onClick={() => this.handleRedirect('/shopping-cart')}>
            <Badge badgeContent={this.state.countValue} classes={{ badge: 'AppHeader__shoppingCartBadge' }} >
              <ShoppingCartOutlined style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : (this.state.lastScrollY > 100 ? "var(--design-secondary-color)" : this.state.designDetails.header.chosenHeaderTheme === "Nexus" ? "#FFF" : "var(--design-secondary-color)"), transition: "color 0.3s" }} />
            </Badge>
          </div>
        </div>
      </div>
    )

    const renderLogoutModal = () => {
      return (
        <CustomoLogoutModal
          open={this.state.isLogoutModalOpen}
          onClose={this.handleLogoutModalClose}
        >
          <Box className="LogoutModalContent">
            <Close className={classes.closeButton} onClick={this.handleLogoutModalClose}/>
            <Typography className="LogoutModalDesc">Are you sure you want to log out of your account?</Typography>
            <Box className="LogoutModalBtns">
              <Button data-test-id="cancleLogoutButtonId" className="modalCancelBtn" onClick={this.handleLogoutModalClose}>Cancel</Button>
              <Button onClick={this.handleLogout} className="modalLogoutBtn"><span className="txtCp">Log</span> <span className="txtLo">out</span></Button>
            </Box>
          </Box>
        </CustomoLogoutModal>
      )
    };

    const renderActivationSuccessModal = () => {
      return (
        <Modal open={this.state.isAccActiveMsgOpen} onClose={this.handleCloseAccActiveMsg}>
          <Grid container className='ActivationSuccessContainer'>
            <IconButton onClick={this.handleCloseAccActiveMsg} className='ActivationSuccessContainer__closeBtn'>
              <CloseIcon />
            </IconButton>
            <Grid item xs={12}>
              <CheckCircleRounded className='ActivationSuccessContainer__successImg' />
            </Grid>
            <Grid item xs={12}>
              <Typography className='ActivationSuccessContainer__title'>{configJSON.emailActiveSuccessTitle}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className='ActivationSuccessContainer__description'>{configJSON.emailActiveSuccessDesc}</Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <Button disableElevation variant="contained" className='ActivationSuccessContainer__button' onClick={this.handleCloseAccActiveMsg}>
                {configJSON.emailActiveSuccessButton}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )
    }

    const handleStyleAppHeader = () => {
      const right = this.state.designDetails.header.chosenHeaderTheme === 'Nexus' ? (this.state.modalType !== 'profile' ? 220 : 180) : "unset"
      const left = this.state.designDetails.header.chosenHeaderTheme === 'Nexus' || this.state.designDetails.header.chosenHeaderTheme === 'Horizon' ? "unset" : "50%"
      const transform = this.state.designDetails.header.chosenHeaderTheme === 'Nexus' || this.state.designDetails.header.chosenHeaderTheme === 'Horizon' || this.state.designDetails.header.chosenHeaderTheme === 'Nexus' ? "unset" : "translateX(-50%)" 
      const width = this.state.designDetails.header.chosenHeaderTheme !== 'Nexus' ? "100%" : "unset"
      const justifyContent = this.state.designDetails.header.chosenHeaderTheme !== 'Nexus'  ? "center" : "flext-start"
      
      return { right, left, transform, width, justifyContent }
    }

    const getClassPopOverMore = () => {
      if (
        (this.state.designDetails.header.chosenHeaderTheme === 'Horizon' && window.location.pathname === "/") || 
        (window.location.pathname !== "/" && (this.state.designDetails.themesAndFont.selectedTheme === "Modern Marvel" && this.state.designDetails.header.chosenHeaderTheme !== "Elevate") || 
        this.state.designDetails.themesAndFont.selectedTheme === "Classic Elegance" && this.state.designDetails.header.chosenHeaderTheme === "Horizon")
      ) return classes.modernMarvelCustomPopover 
      else if (this.state.designDetails.header.chosenHeaderTheme === "Radiance" && window.location.pathname === "/") return classes.radianceCustomPopover 
      else return ''
    }

    const getMenuItems = () => {
      const { right, left, transform, width, justifyContent } = handleStyleAppHeader()

      return (
        <div 
          className="AppHeader__menu" 
          onMouseLeave={this.handleMoreButtonClose} 
          style={
            window.location.pathname !== "/" 
            ? { right: "unset", left: "50%", transform: "translateX(-50%)" } 
            : { right, left, transform, justifyContent, width }
          }
        >
            {!isLoading ? 
            <>
              {this.state.designDetails.header.headerItems.map((item: { itemUrl: string, categoryName: string }, index: number) => (
              (item && 
                  <a 
                    key={index + item.categoryName}
                    onClick={(e) => this.handleRedirect(item.itemUrl)}
                    onMouseEnter={(e) => {
                      this.handleClick(e, item);
                      this.handleMoreButtonClose();
                    }}
                    style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : ((this.state.designDetails.header.chosenHeaderTheme === "Nexus" && this.state.lastScrollY <= 100) ? "#FFF" : "var(--design-secondary-color)") }}
                  >{item.categoryName}</a>)
              ))}
              <Button
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"

                className={this.props.classes.moreButton}
                onMouseEnter={(e) => {
                  this.handleMoreButtonOpen(e);
                  this.handleClose();
                }}
                style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : ((this.state.designDetails.header.chosenHeaderTheme === "Nexus" && this.state.lastScrollY <= 100) ? "#FFF" : "var(--design-secondary-color)") }}
              >
                {configJSON.moreButton}
              </Button>
            </> : Array.from({length: 5}).map((_, index) => (
              <Skeleton key={index} variant='text' width={100} height={30} style={{ margin: '0 8px' }} />
            ))}
            {(menuItems.find((menu: IMenuItem) => menu.categoryName === selectedMenuItem?.categoryName)?.categoryItems || []).length > 0 && (
            <CustomPopOver
              transitionDuration={0}
              classes={{
                root: ((["Horizon", "Radiance"].includes(this.state.designDetails.header.chosenHeaderTheme) && window.location.pathname === "/") || (window.location.pathname !== "/" && this.state.designDetails.themesAndFont.selectedTheme === "Modern Marvel")) ? classes.modernMarvelCustomPopover : '',
              }}
              style={{ minHeight: '200px', height: 'auto' }}
              PaperProps={{ onMouseLeave: this.handleClose }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {menuItems.find((menu: IMenuItem) => menu.categoryName === selectedMenuItem?.categoryName)?.categoryItems?.map((subItem: any, index: number) => (
                <GCustomMenuItemSubLink key={index + subItem.itemName} onClick={() => this.handleRedirect(subItem.itemUrl)} title={subItem.itemName}>{subItem.itemName}</GCustomMenuItemSubLink>
              ))}
            </CustomPopOver>
            )}
            <CustomMenuPopOver
              classes={{
                root: getClassPopOverMore(),
              }}
              transitionDuration={0}
              PaperProps={{
                onMouseLeave: this.handleMoreButtonClose,
              }}
              id="long-menu"
              open={Boolean(this.state.moreButtonAnchor)}
              anchorEl={this.state.moreButtonAnchor}
              disablePortal={window.location.pathname === "/"}
              onClose={this.handleMoreButtonClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              { this.state.menuItems.length === 0 && 
              <Box className={this.props.classes.skeltonContainer}>
              {Array.from({length:configJSON.skeltonItemsLength}).map(
                (_,index) => 
              <Skeleton key={index} variant='text'/>
              )}
              </Box>
              }
              {this.state.menuItems.length > 0 
              && this.state.menuItems.map((item) => {
                if(!item) return null;
                const menu = (
                  <CustomMenuItemLink
                    key={item.id + item.slug}
                    onClick={() => {
                      if (item?.itemUrl || item?.webItemUrl) {
                        this.handleRedirect(item.itemUrl as string || item.webItemUrl as string);
                      }
                    }}
                  >
                    {item.categoryName}
                  </CustomMenuItemLink>
                );

                if (item?.categoryItems?.length === 0) {
                  return menu;
                }

                const subMenu = item?.categoryItems?.map((subItem) => (
                  <CustomMenuItemSubLink
                    onClick={() => {
                      if (subItem?.itemUrl) {
                        this.handleRedirect(subItem.itemUrl);
                      }
                    }}
                    key={subItem.itemName}>{subItem.itemName}
                  </CustomMenuItemSubLink>
                ))
                return (
                  <>
                    {menu}
                    {subMenu}
                  </>
                )

              })}
            </CustomMenuPopOver>
          </div>
      )
    }

    const renderWeb = (
      <div className='AppHeader--wrapper' style={window.location.pathname !== "/" ? { position: "relative", backgroundColor: "#FFF", borderBottomColor: "E2E8F0" } : { top: this.state.isHeaderVisible ? 0 : ["Nexus", "Elevate"].includes(this.state.designDetails.header.chosenHeaderTheme) ? -101 : -135, backgroundColor: this.state.lastScrollY > 100 ? "#FFF" : ["Nexus", "Radiance"].includes(this.state.designDetails.header.chosenHeaderTheme) ? "transparent" : "#FFF", borderBottomColor: ["Nexus", "Radiance"].includes(this.state.designDetails.header.chosenHeaderTheme) ? "transparent" : "#E2E8F0" }}>
        <div className={((["Horizon", "Radiance"].includes(this.state.designDetails.header.chosenHeaderTheme) && window.location.pathname === "/") || (window.location.pathname !== "/" && (this.state.designDetails.themesAndFont.selectedTheme === "Modern Marvel" && this.state.designDetails.header.chosenHeaderTheme !== "Elevate") || (this.state.designDetails.themesAndFont.selectedTheme === "Classic Elegance" && this.state.designDetails.header.chosenHeaderTheme === "Horizon"))) ? "AppHeader template--ModernMarvel" : "AppHeader"}>
          <div 
            className={((["Nexus", "Elevate"].includes(this.state.designDetails.header.chosenHeaderTheme) && window.location.pathname === "/") || (window.location.pathname !== "/" && !(this.state.designDetails.themesAndFont.selectedTheme === "Modern Marvel" && this.state.designDetails.header.chosenHeaderTheme !== "Elevate") && !(this.state.designDetails.themesAndFont.selectedTheme === "Classic Elegance" && this.state.designDetails.header.chosenHeaderTheme === "Horizon"))) || this.state.isSearchOpen ? "AppHeader__logo" : "AppHeader__logo template--ModernMarvel"}
            onClick={() => this.handleRedirect('/')}
            title={this.state.customerServices.storeName}
          >
            {renderLogo()}
          </div>
          {!isSearchOpen ? (
            ((["Horizon", "Radiance"].includes(this.state.designDetails.header.chosenHeaderTheme) && window.location.pathname === "/") || (window.location.pathname !== "/" && (this.state.designDetails.themesAndFont.selectedTheme === "Modern Marvel" && this.state.designDetails.header.chosenHeaderTheme !== "Elevate") || (this.state.designDetails.themesAndFont.selectedTheme === "Classic Elegance" && this.state.designDetails.header.chosenHeaderTheme === "Horizon"))) ? null : getMenuItems()
          ) : (
            <div className="AppHeader__search" id="AppHeader__SearchContainer">
              <OutlinedInput 
              style={{color:isShowWhiteColour , borderColor:isShowWhiteColour , outlineColor:isShowWhiteColour , border : isSearchOpen ? `1px solid ${isShowWhiteColour}`:'none'}}
                onBlur={() => this.handleSearchVisibility('blur')}
                value={searchValue}
                onChange={(e) => this.setSearchValue(e.target.value)}
                onKeyDown={(e) => this.handleSearchKeyDown(e.key)}
                placeholder={configJSON.searchPlaceholder}
                classes={{
                  root: isSearchOpen ? [classes.search, classes.searchOpen].join(' ') : classes.search,
                  input: classes.input,
                }}
                endAdornment={
                  <>
                    <Close 
                    style={{fill:isShowWhiteColour}}
                      className={classes.closeIcon}
                      onClick={this.handleCrossClickedSearch}
                    />
                    <Search style={{fill:isShowWhiteColour}}  className={classes.searchIconHeader} />
                  </>
                }
              />
            </div>
          )}
          <Popper
            open={isSearchOpen && searchItems.length > 0}
            anchorEl={document.getElementById('AppHeader__SearchContainer')}
            className={classes.searchPopper}
            style={{ width: document.getElementById('AppHeader__SearchContainer')?.clientWidth }}
            placement="bottom-start"
          >
            <List className={classes.searchList}>
              {renderSearchItems}
            </List>
          </Popper>
          <div style={{ display: 'flex', alignItems: 'center', zIndex: 100000 }}>
            {isSearchEnabled && !isSearchOpen && (
              <Search
                onClick={this.handleSearchBtnClicked}
                style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : ((this.state.designDetails.header.chosenHeaderTheme === "Nexus" && this.state.lastScrollY <= 100) ? "#FFF" : "var(--design-secondary-color)"), cursor: "pointer", transition: "color 0.3s", zIndex:10 }}
              />
            )}
            <Box className={`${classes.loginBox}`} onClick={this.handleLogin}>
              <PersonOutlineOutlined style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : ((this.state.designDetails.header.chosenHeaderTheme === "Nexus" && this.state.lastScrollY <= 100) ? "#FFF" : "var(--design-secondary-color)"), transition: "color 0.3s" }} />
              {this.state.modalType !== 'profile' && <Typography className={classes.loginText} style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : ((this.state.designDetails.header.chosenHeaderTheme === "Nexus" && this.state.lastScrollY <= 100) ? "#FFF" : "var(--design-secondary-color)"), transition: "color 0.3s" }}>{configJSON.login}</Typography>}
            </Box>
              <Menu
                onClose={this.handleMenuProfileClose}
                open={Boolean(this.state.anchorElement)}
                anchorEl={this.state.anchorElement}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: menuStyles
                }}
                disableAutoFocusItem
              >
                <CustomoMenuItem>
                  <Box className="menuItem" onClick={() => this.handleRedirect('/profile')}>
                    <img src={profile} alt={configJSON.accountTitle} />
                    <Typography>{configJSON.accountTitle}</Typography>
                  </Box>
                </CustomoMenuItem>
                <CustomoMenuItem>
                  <Box className="menuItem" onClick={() => this.handleRedirect('/profile/address-management')}>
                    <img src={location} alt={configJSON.manageAddressTitle} />
                    <Typography>{configJSON.manageAddressTitle}</Typography>
                  </Box>
                </CustomoMenuItem>
                <CustomoMenuItem>
                  <Box className="menuItem" onClick={() => this.handleRedirect('/profile/orders')}>
                    <img src={order} alt={configJSON.orderTitle} />
                    <Typography>{configJSON.orderTitle}</Typography>
                  </Box>
                </CustomoMenuItem>
                <CustomoMenuItem>
                  <Box className="menuItem" onClick={()=> {this.handleLogoutModalOpen(); this.handleMenuProfileClose();}}>
                    <img src={logout} alt={configJSON.orderTitle} />
                    <Typography className='redColor'>{configJSON.logoutTitle}</Typography>
                  </Box>
                </CustomoMenuItem>

              </Menu>
            <div className="AppHeader__shoppingCartContainer">
              <div className="AppHeader__shoppingCartIcon" onClick={() => this.handleRedirect('/shopping-cart')} >
              <Badge badgeContent={this.state.countValue} classes={{ badge: 'AppHeader__shoppingCartBadge' }} >
                <ShoppingCartOutlined style={{ color: window.location.pathname !== "/" ? "var(--design-secondary-color)" : ((this.state.designDetails.header.chosenHeaderTheme === "Nexus" && this.state.lastScrollY <= 100) ? "#FFF" : "var(--design-secondary-color)"), transition: "color 0.3s" }} />
              </Badge>
            </div>
          </div>
          </div>
          {renderLogoutModal()}
        </div>
        {((["Horizon", "Radiance"].includes(this.state.designDetails.header.chosenHeaderTheme) && window.location.pathname === "/") || (window.location.pathname !== "/" && (this.state.designDetails.themesAndFont.selectedTheme === "Modern Marvel" && this.state.designDetails.header.chosenHeaderTheme !== "Elevate") || (this.state.designDetails.themesAndFont.selectedTheme === "Classic Elegance" && this.state.designDetails.header.chosenHeaderTheme === "Horizon"))) ? <HeaderMenuItemsWeb menuItems={getMenuItems()} /> : null}
      </div>
    )

    return (
      <>
      <Helmet
        title={this.state.seoData.metaTitle || this.state.customerServices.storeName}
        meta={[
          { name: 'description', content: this.state.seoData.metaDescription },
          { name: 'keywords', content: this.state.seoData.metaKeywords }
        ]}
      >
        {gtmScript}
      </Helmet>
      {this.state.isAccActiveMsgOpen && renderActivationSuccessModal()}
      {isMobile ? renderMobile : renderWeb}
        {(this.state.openLogin  && (!(this.state.modalType === "profile" && !isMobile))) &&
          <EmailAccountLoginBlock 
            isMobileScreen={this.state.isMobile} 
            isOpen={this.state.openLogin} 
            navigation={this.props.navigation} 
            id="email-login" 
            handleSignUp={this.handleSignUp} 
            handleLogin={this.handleLogin} 
            handleMagicLink={this.handleMagicLink}
            onLoginClose = {this.handleLoginClose}
            screenType = {this.state.modalType}
            onLoginSuccess = {this.handleLoginSuccess}
            goToHomePage = {this.navigateToHome}
            showProfile={this.state.showProfile}
          />
        }
        {
          this.state.signUpModal &&  
          <EmailAccountRegistration 
            id={this.props.id}
            navigation={this.props.navigation}
            handleLogin={this.handleLogin}
            handleMagicLink={this.handleMagicLink}
            handleSignUp={this.handleSignUp} 
            handleVerifyMail={() => {}}
            isMobileScreen={this.state.isMobile}
            isOpen={this.state.signUpModal}
            onSignUpClose={this.handleCloseSignUp}
          />
        }
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const menuStyles = {
  borderRadius: "8px",
  border: "1px solid #E2E8F0",
  marginLeft: "1rem",
  padding: ".2rem .3rem",
  marginTop: "2.9rem",
}
const CustomoMenuItem = styled(MenuItem)({
  width: "184px",
  height: "36px",
  borderRadius: "8px",
  '&:hover': {
    backgroundColor: "var(--design-accent-color)",
  },
  "& .menuItem":{
    display: "flex",
    gap: ".6rem",
    width: "100%",
    height: "100%",
    alignItems: "center",
    cursor: "pointer",
  },
  "& img":{
    height: "12px",
    width: "12px",
    objectFit: "fill",
  },
  "& p":{
    fontFamily: "var(--design-body-text-font)",
    fontSize: "14px",
    lineHeight: "22px",
    color: "var(--design-secondary-color)",
    fontWeight: "400",
  },
  "& .redColor": {
    color: "#DC2626"
  }

})
const CustomPopOver = styled(Popover)({
  height:"450px !important",
  inset: '100px 0 0 0 !important',
  '& .MuiPopover-paper': {
    borderRadius: "0",
    boxShadow: '0px 2px 8px 0px #00000014',
    top: "0 !important",
    padding: "12px 40px",
    display:"grid",
    gridAutoFlow:"column",
    gridTemplateRows:"repeat(8, auto)",
    minWidth:"284px",
    overflowX: 'auto' as 'auto',
    boxSizing: 'border-box' as 'border-box',
    '&::-webkit-scrollbar': {
      height: 4,
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: 2,
    },
  },
  '& div:first-child': {
    inset: 'unset !important',
  },
})

const CustomMenuPopOver = styled(Popover)({
  width:"100%",
  height:"450px",
  inset: '100px 0 0 0 !important',
  // position: 'absolute !important' as 'absolute',
  '& .MuiPopover-paper': {
    borderRadius: "0",
    boxShadow: '0px 2px 8px 0px #00000014',
    left: "0 !important",
    top: "0 !important",
    padding: "12px 40px",
    display:"flex",
    flexDirection:"column",
    flexWrap:"wrap",
    gap:"4px",
    height:"inherit",
    width:"inherit",
    overflowX: 'auto' as 'auto',
    boxSizing: 'border-box' as 'border-box',
    maxWidth: "100%",
    '&::-webkit-scrollbar': {
      height: 4,
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: 2,
    },
  },
  '& div:first-child': {
    inset: 'unset !important',
  },
})

const CustomMenuItemLink = styled(MenuItem)({
  color:"var(--design-primary-color)",
  fontSize:"18px",
  fontWeight:600,
  padding:"4px 0",
  fontFamily: 'var(--design-body-text-font)',
  lineHeight: '26px',
  textAlign: 'left',
  '&:hover': {
    backgroundColor:'#fff',
    fontWeight:800,
  },
})

const CustomMenuItemSubLink = styled(MenuItem)({
  fontSize:"16px",
  padding:"4px 0",
  color:"var(--design-secondary-color)",
  fontWeight:400,
  fontFamily: 'var(--design-body-text-font)',
  lineHeight: '22px',
  '&:hover': {
    fontWeight: 600, 
    backgroundColor:'#fff'
  },
})

const GCustomMenuItemSubLink = styled(MenuItem)({
  display: 'inline-block',
  fontSize:"16px",
  padding:"4px 20px 4px 0",
  color:"var(--design-secondary-color)",
  fontWeight:400,
  fontFamily: 'var(--design-body-text-font)',
  lineHeight: '32px',
  minWidth: "240px",
  maxWidth: "240px",
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: "40px",
})
const styles = () => ({
  skeltonContainer:{
    display:'flex',
    flexDirection:"column" as "column",
    flexWrap:"wrap" as "wrap",
    height:"100%",
    width:"100%",
    justifyContent:"space-between",
  },
  moreButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    cursor: 'pointer',
    color: "var(--design-secondary-color)",
    fontFamily: 'var(--design-body-text-font)',
    fontWeight: 500,
    fontSize: '18px',
    letterSpacing: '0.9px',
    lineHeight: '18px',
    textDecoration: 'none',
    textTransform: 'none' as "none",
  },
  stylePopover:{
    padding:"1rem",
    marginTop:"1rem",
    "& .MuiPopover-root":{
      padding:"2rem"
    },
  },
  search: {
    borderRadius: 10,
    fontSize: 16,
    fontWeight: 400,
    width: '100%',
    maxWidth: 360,
    height: 44,
    paddingLeft: 8,
    transition: 'max-width 0.5s ease-in-out',
    fontFamily: 'var(--design-body-text-font)',
  },
  searchOpen: {
    maxWidth: "45vw",
    '& fieldset': {
      border: '1px solid white !important',
    },
  },
  searchMobile: {
    borderRadius: 8,
    height: 56,
    placeSelf: 'center',
    width: 'calc(100% - 32px)',
    maxWidth: '100%',
    paddingRight: 8,
    backgroundColor: '#fff',
  },
  input: {
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    },
  },
  searchIcon: {
    fill: 'var(--design-secondary-color)',
    marginRight: 8,
  },
  searchIconHeader: {
    fill: 'var(--design-secondary-color)',
    marginRight: 8,
    marginLeft: 16,
    cursor: 'default',
  },
  closeIcon: {
    fill: 'var(--design-secondary-color)',
    cursor: 'pointer',

  },
  currencyOpenIcon: {
    fill: 'var(--design-secondary-color)',
  },
  currencyOpenIconMobile: {
    transform: 'rotate(90deg)',
    marginRight: 16,
  },
  select: {
    fontWeight: 700,
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: '19.5px',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  currencySelectMobile: {
    padding: '16px',
    borderTop: '1px solid #E2E8F0',
    borderBottom: '1px solid #E2E8F0',
    backgroundColor: '#fff',
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  currencyPopoverMobile: {
    left: '0 !important',
  },
  paper: {
    border: '1px solid #E2E8F0',
    borderRadius: 8,
    boxShadow: '0px 2px 8px 0px #00000014',
    marginTop: 48,
    maxHeight: "422px",
    maxWidth: "520px",
    padding: "0 24px 24px 24px",
    overflowY: 'auto' as 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: 2,
    },
  },
  menuIcon: {
    cursor: 'pointer',
    color: 'var(--design-secondary-color)',
  },
  menuActiveItem: {
    fontSize: 14,
    padding: "4px 8px",
    fontFamily: 'var(--design-body-text-font)',
    color: 'var(--design-secondary-color)',
    opacity: .8,
  },
  drawerPaper: {
    width: '100vw',
    backgroundColor: '#FFFFFF',
  },
  closeButton: {
    position: 'absolute' as 'absolute',
    right: 16,
    top: 16,
    cursor: 'pointer',
  },
  list: {
    padding: "0",
    maxHeight: "calc(100% - 156px)",
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column' as 'column',
    overflowY: 'auto' as 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: 2,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: 2,
    },
  },
  listItem: {
    color: 'var(--design-secondary-color)',
    borderBottom: '1px solid #E2E8F0',
    wordWrap: 'break-word' as 'break-word',
    '&:first-child': {
      borderTop: '1px solid #E2E8F0',
    },
  },
  listItemText: {
    lineHeight: '1.5',
  },
  listItemTextBold: {
    lineHeight: '1.5',
    fontWeight: 600,
  },
  mobileLogin: {
    background: 'var(--design-primary-color) !important',
    width: 'calc(100% - 32px)',
    height: 56,
    minHeight: 56,
    padding: '10px 16px',
    borderRadius: 10,
    fontFamily: 'var(--design-body-text-font)',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '22px',
    color: '#FFFFFF',
    margin: 'auto auto 20px auto',
    textTransform: 'none' as 'none',
  },
  searchPopper: {
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
    maxHeight: 422,
    overflowY: 'auto' as 'auto',
    '&::-webkit-scrollbar': {
      width: 20,
    },
    '&::-webkit-scrollbar-track': {
      background: '#CBD5E1',
      borderRadius: "0 10px 10px 0",
      borderRight: '10px solid #fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'var(--design-primary-color)',
      borderRadius: "0 10px 10px 0",
      borderRight: '10px solid #fff',
    },
  },
  searchList: {
    padding: "16px 24px",
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  loginBox:{
    display:'flex',
    alignItems:'center',
    marginLeft:'20px',
    cursor:'pointer',
    gap: ".4rem",
    "& img":{
      width:"17px",
      height: "17px",
      objectFit: "fill",
    }
  },
  loginText:{
    fontSize:'16px',
    fontWeight:500,
    color:'var(--design-secondary-color)',
    textTransform: "none" as "none" ,
    fontFamily: 'var(--design-body-text-font)',
  },
  searchLoader: {
    margin: 'auto',
    color: 'var(--design-accent-color)',
    width: "24px !important",
    height: "24px !important",
  },
  searchListItem: {
    paddingLeft: 0,
    paddingRight: 0,
    wordWrap: 'break-word' as 'break-word',
    '&:not(:last-child)': {
      borderBottom: '1px solid #E2E8F0',
    },
    "&:hover": {
      backgroundColor: 'transparent'
    }
  },
  modernMarvelCustomPopover: {
    inset: '134px 0 0 0 !important',
  },
  radianceCustomPopover: {
    inset: '30px 0 0 0 !important',
  }
});

const CustomoLogoutModal = styled(Modal)({
  "& .LogoutModalContent": {
    maxWidth: "600px",
    width: "calc(100% - 40px)",
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxSizing: "border-box",
    borderRadius: "8px 8px 32px 8px",
    outline: 'none',
    backgroundColor: "#fff",
    "& img": {
      cursor: "pointer",
      position: "absolute",
      top: "20px",
      right: "16px",
    }
  },
  "& .LogoutModalDesc": {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#0F172A",
    padding: "50px 40px 20px 40px",
  },
  "& .LogoutModalBtns": {
    borderTop: "1px solid #E2E8F0",
    display: "flex",
    gap: "4px",
    justifyContent: "flex-end",
    padding: "24px 40px 24px 40px",
    "& button": {
      width: "120px",
      borderRadius: "8px",
    },
    "& .modalCancelBtn": {
      padding: "12px",
      "& span": {
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: "16px",
        textTransform: "capitalize",
        color: "var(--design-primary-color)",
      }
    },
    "& .modalLogoutBtn": {
      padding: "12px",
      background: "var(--design-primary-color) !important",
      "& span": {
        display: "flex",
        gap: "2px",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: "16px",
        color: "var(--Basic-White, #FFF)",
        "& .txtLo": {
          textTransform: "lowercase"
        },
        "& .txtCp": {
          textTransform: "capitalize"
        },
      }
    }
  }
});

export default withRouter(withStyles(styles)(AppHeader));
// Customizable Area End
